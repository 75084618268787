import './App.css';

import {useEffect, useState} from 'react';
import {Button, Form, InputGroup, FloatingLabel} from 'react-bootstrap';

import urepplogo from './images/urepp_logo.png';
import nspnLogo from './images/nspn_logo.png';
import nfhsLogo from './images/nfhs_logo.svg';
/*
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, light, thin, duotone, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
*/

import UReppApi from './Api.js';
const api = new UReppApi();

const App = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [games, setGames] = useState([]);

    function filterGames(e) {
        const selDate = window.fsDate.value;
        const selSport = window.fsSport.value;
        const selGender = window.fsGender.value;
        const selDivision = window.fsDivision.value;
        //console.log('filterGames', selGender, selSport, selDivision);

        const qsDT = selDate !== 'all' ? `[data-date-type="today"]` : ``;
        const qsS = selSport !== 'all' ? `[data-sport-type="${selSport}"]` : ``;
        const qsG = selGender !== 'all' ? `[data-gender="${selGender}"]` : ``;
        const qsD = selDivision !== 'all' ? `[data-division="${selDivision}"]` : ``;

        const allItems = document.querySelectorAll('div.game-item');
        allItems.forEach(e => e.classList.add('d-none'));

        const showItems = document.querySelectorAll(`div.game-item${qsDT}${qsS}${qsG}${qsD}`);
        showItems.forEach(e => e.classList.remove('d-none'));
    }

    function getFilterCtrl(sportTypes) {
        return (
            <InputGroup className="p-2 col-12 col-md-6 d-flex">
                <FloatingLabel className="col col-md-2 me-2" controlId="fsDate" label="Date" style={{color: '#212529', fontSize: '80%'}}>
                    <Form.Select onChange={filterGames}>
                        <option value="all">All</option>
                        <option value="today">Today</option>
                    </Form.Select>
                </FloatingLabel>

                <FloatingLabel className="col col-md-6 me-2" controlId="fsSport" label="Sport" style={{color: '#212529', fontSize: '80%'}}>
                    <Form.Select onChange={filterGames}>
                        <option value="all">All</option>
                        {sportTypes?.map(st => {
                            return (
                                <option key={st.code} value={st.code}>{st.desc}</option>
                            )
                        })}
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className="col me-2" controlId="fsGender" label="Gender" style={{color: '#212529', fontSize: '80%'}}>
                    <Form.Select onChange={filterGames}>
                        <option value="all">All</option>
                        <option value="M">Boys</option>
                        <option value="F">Girls</option>
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className="col me-2 d-none" controlId="fsDivision" label="Division" style={{color: '#212529', fontSize: '80%'}}>
                    <Form.Select onChange={filterGames}>
                        <option value="all">All</option>
                        {games?.divisionTypes?.map(dt => {
                            return (
                                <option key={dt.code} value={dt.code}>{dt.desc}</option>
                            )
                        })}
                    </Form.Select>
                </FloatingLabel>
            </InputGroup>
        );
    }

    function getGameCtrl(g) {
        return (
            <div className="game-item game-ctrl-container mx-1 mt-1 p-1 d-flex flex-column" key={g.xKey} data-date-type={g.dateType} data-sport-type={g.sportType} data-gender={g.gender} data-division={g.teams[0].division || '0A'}>
                <div className={"game-ctrl-sport-bg " + g.sportTypeIcon}></div>
                <div className="d-flex flex-row w-100 game-ctrl-logo-container">
                    <img className="align-self-center team game-ctrl-logo-img" src={g.teams[0]?.logo} alt="Team Logo" />
                    <img className="ms-auto me-2 align-self-center opponent game-ctrl-logo-img" src={g.teams[1]?.logo} alt="Team Logo" />
                </div>
                <div className="d-flex flex-fill game-ctrl-spacer"></div>
                <div className="d-flex flex-row w-100 game-ctrl-spacer">
                    <div className="w-50 align-self-center game-ctrl-team-info left flex-nowrap">{g.teams[0].shortName} {g.teams[0].mascot}</div>
                    <div className="w-50 align-self-cente game-ctrl-team-info right">{g.teams[1].shortName} {g.teams[1].mascot}</div>
                </div>
                <div className="d-flex flex-row w-100 game-ctrl-game-info">
                    <div className="w-50 align-self-center game-ctrl-team-info left">{g.startDateFormatted}</div>
                    <div className="w-50 align-self-center game-ctrl-team-info right">{g.startTimeFormatted} {g.sportTypeSymbol}️</div>
                </div>
                <div className="d-flex game-ctrl-buttons-container ms-autox">
                    <div className={"game-ctrl-play-btn me-1 " + (g.isLive === true ? '' : 'd-none')}>
                        <i className="fa-solid fa-play align-self-center fa-fw" />
                    </div>

                    <div className={"game-ctrl-info-btn me-1 " + (g.paymentRequired === 'true' ? '' : 'd-none')}>
                        <i className="fa-solid fa-dollar-sign align-self-center fa-fw" />
                    </div>
                    <div className="ms-auto"></div>

                    <Button className={"me-1 game-ctrl-icon-button " + (g.tickets?.length && g.tickets?.[0]?.gofanJson?.eventLink ? '' : 'd-none')} variant="success" type="button" href={g.tickets?.[0]?.gofanJson?.eventLink} target="_blank">
                        <i className="fa-solid fa-ticket align-self-center fa-fw" />
                    </Button>
                    <Button className="game-ctrl-button" variant="primary" type="button" href={g.siteUrl} target="-blank">
                        <img className="btn-logo-img mx-2" src={g.type === 'urepp' ? urepplogo : g.type === 'nfhs' ? nfhsLogo : nspnLogo} alt="Provider Logo"/>
                        <span className="me-2">{g.typeDesc}</span>
                    </Button>
                </div>
            </div>
        );
    }

    useEffect(() => {
        api.getGames().then(data => {
            setGames(data);

            setIsLoaded(true);
        });
    }, []);

    useEffect(() => {
        if(isLoaded) {
            setIsPageLoaded(true);
            filterGames();
        }
    }, [isLoaded]);

    return (
        <div className="app">
            <header className="app-header d-flex flex-column justify-content-center">
                <div className="filter-container">
                    {getFilterCtrl(games?.sportTypes)}
                </div>
            </header>
            <div className="app-content d-flex justify-content-center">
                <div id="divUpcomingGames" className="games-container d-flex flex-wrap justify-content-center">
                    {games?.items?.map(g => {
                        return getGameCtrl(g);
                    })}
                </div>
            </div>
        </div>
    )
};


export default App;
