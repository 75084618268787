class UReppApi {
    constructor() {
        this.baseUrl = 'https://urepp.tv';
        this.baseRemoteUrl = this.baseUrl; //'https://local.urepp.tv';
    }

    get stdHeaders() {
        return { 'Content-Type': 'application/json' };
    }

    jsonData(data) {
        if(data) {
            return JSON.stringify(data);
        }
    }

    async jsonResp(resp) {
        if(resp?.ok) {
            return await resp.json()
        }
    }

    async getGames() {
        try {
            const resp = await fetch(
                `${this.baseRemoteUrl}/api/public/game/livestream/links`,
                //`/api/public/game/livestream/links`,
                {
                    method: 'GET',
                    headers: this.stdHeaders,
                }
            );

            if(resp?.ok) {
                return await resp.json();
            }
        } catch(err) {
            console.log('err', err.message);
        }
    }
}

export default UReppApi;